div {
  margin: 0 auto;
  }
  
  .personPageEditContentContainer
  {
    width:560px;
    min-height: 400px;
    text-align:left;
    margin-left:22px;
    margin-right:22px;
    margin-top:40px;
    margin-bottom:80px;
  }
  
  .personPageEditUserContainer
  {
    width:100%;
    text-align:center;
    background-color:#FFFFFF;
  }
  
  .personPageEditFloat
  {
    width:546px;
  }
  
  .personPageEditThumbnailContainer
  {
    width:560px;
    margin-top:20px;
    text-align:left;
  }
  
  .personPageEditThumbnailText
  {
    width:318px;
    text-align:left;
    margin-left:15px;
  }
  
  
  .personPageDarkText
  {
    color:#000000;
    margin-top:5px;
  }
  
  .personPageLightText
  {
    color: #666666  ;
    margin-top:5px;
  }
  
  .personPageEditInputContainerSmaller
  {
    width:150px;
    height:30px;
  }
  
  .personPageEditInputContainerSmallish
  {
    width:150px;
    height:30px;
  }
  
  .personPageInputSmallish
  {
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius:2px;
    color:#000000;
    background-color:#f5f5f5;
    height:26px;
    width:140px;
    text-align:left;
    border: 1px solid #e5e5e5;
    padding-left:7px;
  }
  
  .personPageEditInputContainerTiny
  {
    width:54px;
    height:30px;
  }
  
  .userSpacerImage1
  {
    width:300px;
    margin-top:10px;
    margin-bottom: 10px;
  }
  
  .personPageInputTiny
  {
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius:2px;
    color:#000000;
    background-color:#f5f5f5;
    height:26px;
    width:44px;
    text-align:center;
    border: 1px solid #e5e5e5;
  }
  
  
  .personPageEditInputContainerSmall
  {
    width:275px;
    height:auto;
    margin-top:15px;
    display: flex;
    flex-direction: column;
  }
  
  .personPageInputSmall
  {
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius:2px;
    color:#000000;
    background-color:#f5f5f5;
    height:26px;
    width:265px;
    text-align:left;
    border: 1px solid #e5e5e5;
    padding-left:7px;
  }
  
  .personPageEditInputContainerLarge
  {
    width:560px;
    height:45px;
    margin-top:15px;
  }
  
  .personPageInputLarge
  {
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius:2px;
    color:#000000;
    background-color:#f5f5f5;
    height:30px;
    width:550px;
    text-align:left;
    border: 1px solid #e5e5e5;
    padding-left:7px;
  }
  
  .personPageTextInputLabel
  {
    color: #666666;
    margin-left:2px;
    margin-bottom:2px;
  }
  
  .personPagePasswordLabel
  {
    word-wrap:break-word;
    color:#666666  ;
    width:560px;
    margin-left:4px;
    margin-top:8px;
  }
  
  .userPageEditAdminForm
  {
    margin-top:20px;
    margin-bottom:20px;
  }
  
  .userEditUserSpacerImage1
  {
    margin-top:25px;
    width:560px;
    overflow:hidden;
  }
  
  .userEditUserButtonContainer
  {
    margin-top:10px;
    width:560px;
  }
  
  .userEditAdminButton
  {
    width:auto;
    padding-right:24px;
    padding-left:24px;
    margin-right:10px;
    height:30px;
    line-height:30px;
    color:#FFFFFF;
    text-align:center;
    cursor:pointer;
  }
  
  .userEditAdminButtonSave
  {
    width:auto;
    padding-right:30px;
    padding-left:30px;
    margin-right:0px;
    height:30px;
    line-height:30px;
    color:#FFFFFF;
    text-align:center;
    cursor:pointer;
  }
  
  .userEditInputContainerSmall
  {
    width:143px;
    height:34px;
    line-height:30px;
    color:#666666;
    cursor:pointer;
    text-align:left;
    padding-left:7px;
    color:#000000;
  }
  
  .userEditInputContainer
  {
    width:268px;
    height:34px;
    line-height:30px;
    color:#666666;
    cursor:pointer;
    text-align:left;
    padding-left:7px;
    color:#000000;
  }
  
  .userEditTextInputSmall
  {
    color:#000000;
    background-color:transparent;
    height:30px;
    width:120px;
    text-align:left;
    border: 0px solid #f5f5f5;
    outline:none;
    overflow:hidden;
  
  }
  
  .userEditTextInput {
    color: #000000;
    background-color: transparent;
    height: 30px;
    text-align: left;
    border:  0px solid #f5f5f5;
    outline: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 238px;
  }
  
  .measurespan
  {
    max-width: 500px;
  }
  
  .personEditStatusButton
  {
    color:#00BF64;
    margin-top:10px;
    margin-bottom:10px;
    margin-left:5px;
  }
  
  .personLinespacer
  {
    width:10px;
    height:30px;
  }
  
  .personLinespacer2
  {
    width:10px;
    height:30px;
    margin-top:15px;
  }
  
  .personLinespacer3
  {
    width:10px;
    height:1px;
    margin-top:14px;
  }
  
  .personEditProfileButton
  {
    width:auto;
    padding-right:24px;
    padding-left:24px;
    margin-right:-2px;
    margin-top:1px;
    height:30px;
    line-height:30px;
    color:#666666;
    text-align:center;
    cursor:pointer;
  }
  
  .personTagContainer
  {
    width:275px;
    height:45px;
    margin-top:15px;
  }
  
  .personInfoTagName
  {
    color: #666666;
    margin-bottom:2px;
  }
  
  .personInfoTagValue
  {
    margin-top:2px;
    color:#000000;
  }
  
  .indexLinespacer
  {
    width:4px;
    height:45px;
    margin-top:15px;
  }
  
  .indexSelectorItemSmall
  {
    min-width: 200px;
    max-width:475px;
    height:auto;
    cursor:pointer;
    margin-top:10px;
    margin-bottom:10px;
    display: flex;
    white-space: normal;
  }
  
  .indexSelectorItemAuto
  {
    width:auto;
    height:auto;
    cursor:pointer;
    margin-top:20px;
    display: flex;
    white-space: normal;
  }
  
  .personPageEditInputContainerTinyBillrate
  {
    width:95px;
    height:30px;
    margin-top:15px;
  }
  
  .personPageInputTinyBillrate
  {
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius:2px;
    color:#000000;
    background-color:#f5f5f5;
    height:26px;
    width:64px;
    text-align:center;
    border: 1px solid #e5e5e5;
  }
  
  .indexSelectorText
  {
    width:510px;
    line-height: 20px;
  }
  
  .indexSelectorTextSmall
  {
    width:220px;
  }
  
  .indexSelectorIcon
  {
    width:20px;
    margin-right:10px;
  }
  
  .indexSpan
  {
    color:#1A1A1A;
    line-height: 20px;
  }
  
  .personpageViewContainer
  {
    width:560px;
    text-align:left;
    margin-top:40px;
    margin-left:30px;
    margin-right:30px;
  }
  
  .personPageTagEditContainer
  {
    width: 560px;
    margin-top: 20px;
  }
  
  .personPageTagAreaDiv
  {
    margin-top: 15px;
  }
  
  .personPageTagEditLabel
  {
    margin-top: 15px;
  }
  
  .adduserAdminFormLabel
  {
    margin-bottom:15px;
  }  